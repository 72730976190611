let ethers = require("ethers");
let abi = require("./abi.js");
import store from "../store"

let detectEthereumProvider = require('@metamask/detect-provider');
let provider;
let contract;

export default {
    // 初始化
    init: async (status) => {
        if (status) {
            provider = await new ethers.providers.Web3Provider(await detectEthereumProvider());
            contract = await new ethers.Contract(store.state.contractAddress, abi, provider.getSigner());
        } else {
            provider = await new ethers.providers.JsonRpcProvider(store.state.rpcUrl);
            contract = await new ethers.Contract(store.state.contractAddress, abi, provider)
}
    },
    mint: async (token, amount) => {
        console.log('token', token)
        console.log('amount', amount)
        let tx
        if (token === 'BUSD'){
            tx = await contract.mint_busd(amount)
        }else{
            tx = await contract.mint_bpt(amount)
        }
        
        return tx
    },
    getRemainAmount: async () => {
        let amount = await contract.create_amount()
        return 6666 - ethers.utils.formatUnits(amount, "wei")
    },
    getMaxAmount: async (address) => {
        let amount = await contract.balanceOf(address)
        console.log('amount', ethers.utils.formatUnits(amount, "wei"))
        return 10 - ethers.utils.formatUnits(amount, "wei")
    },
    getOtherTime: async () => {
        let time = await contract.start_time()
        return ethers.utils.formatUnits(time, "wei")
    },
}