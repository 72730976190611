export default {
  methods:{
    async connectWallet(){
      if (typeof window.ethereum !== 'undefined') {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          this.$store.commit('updateAccount', accounts[0])
          this.$cookies.set('account', accounts[0])

          let provider = new this.$eth.providers.Web3Provider(window.ethereum);
          let network = await provider.getNetwork();

          // get chain id
          await this.checkChainId(network.chainId)
          window.ethereum.on('chainChanged', this.checkChainId);
        } catch (error) {
          if (error.code === 4001) {
            this.$toasted.show("Metamask Not Connected");
          }
        }
      }else{
        this.$toasted.show('Please install MetaMask');
      }
    },
    async checkChainId(chainId){
      console.log('chainId', chainId)
      let isBsc = this.$store.state.chainId.includes(chainId)
      this.$store.commit('updateIsBsc', isBsc)
      if (!isBsc && this.$cookies.get('account')){
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: this.$store.state.chainId[0] }],
          });
          window.location.reload()
        } catch (switchError) {
          if (switchError.code === 4001){
            this.$toasted.show('Please Switch to Binance Smart Chain')
          }else{
            this.$toasted.show('Switch Error, Please Switch to Binance Smart Chain')
          }
        }
      }
    },
  }
}


