<template>
  <v-app id="app">
    <v-app-bar
      app
      dense
      dark
      flat
      color="black"
      :height="$store.state.isDesktop ? 90 : 70"
    >
      <v-container class="d-flex align-center">
        <v-icon class="d-md-none d-block" @click="navShow = true">mdi-menu</v-icon>
        <v-spacer></v-spacer>
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/img/logo-baby-pig.webp"
          transition="scale-transition"
          :width="$store.state.isDesktop ? 90 : 64"
        />

        <v-spacer></v-spacer>
        <v-btn text dark
          class="d-md-block d-none cancel-btn-tobig px-2"
          v-for="(item, i) in navbar"
          :key="i"
          @click="$vuetify.goTo(`#${item.ele}`)"
        >
          {{ item.text }}
        </v-btn>
        <v-spacer class="d-md-block d-none"></v-spacer>

        <a
          rel="noreferrer noopener"
          target="_blank"
          class="mr-5 d-md-flex align-center d-none"
          v-for="item in $store.state.community"
          :key="item.text"
          :href="item.link"
        >
          <img
            :alt="item.text"
            :src="`${require(`@/assets/img/icon-${item.text}-pink.svg`)}`"
            width="24"
          />
        </a>

        <v-btn
          color="primary"
          rounded
          class="mr-5 d-md-block d-none"
          @click="changeLang()"
        >
          中/En
        </v-btn>

        <v-btn v-if="$store.state.account" rounded color="primary" class="px-2" @click="connectWallet()">
          {{ `${$store.state.account.slice(0, 5)}...${$store.state.account.slice(38)}` }}
        </v-btn>
        <v-btn v-else rounded color="primary" @click="connectWallet()">
          <img src="@/assets/img/icon-connect.svg" alt="connect-wallet" width="30">
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="navShow" fixed style="width: 100vw;" height="100vh" color="rgba(0, 0, 0, .85)" class="d-block d-md-none">
      <div class="ma-10">
        <v-icon dark @click="navShow=false">mdi-close</v-icon>
        <div class="d-flex flex-column justify-center align-center white--text">
          <div 
            v-for="(item, i) in navbar"
            :key="i"
            :style="{borderBottom: `solid 1px ${$vuetify.theme.themes.light.primary}`}"
            class="font-dincond w-full py-5 text-center can-click text-capitalize"
            @click="$vuetify.goTo(`#${item.ele}`), navShow=false"
          >
            {{ item.text }}
          </div>

          <v-btn rounded dark color="primary" class="my-10 font-dincond" @click="changeLang(), navShow=false">
            中/En
          </v-btn>
          <div class="d-flex">
            <a :href="item.link" target="_blank" rel="noreferrer noopener" class="can-click mr-3 d-flex" v-for="(item) in $store.state.community" :key="item.type">
              <img :src="`${require(`@/assets/img/icon-${item.text}-pink.svg`)}`" :alt="item.type" width="23px">
            </a>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import base from '@/mixin/base'
export default {
  name: 'App',
  mixins: [base],
  data: () => ({
    navbar: [
      {
        text: 'About',
        ele: 'about'
      },
      {
        text: 'Piggynomics',
        ele: 'piggynomics'
      },
      {
        text: 'RoadMap',
        ele: 'roadmap'
      },
      {
        text: 'Team',
        ele: 'team'
      },
      {
        text: 'FAQs',
        ele: 'faq'
      },
    ],
    navShow: false
  }),
  methods:{
    scrollTo(height){
      window.scrollTo({top: height, behavior: 'smooth'});
    },
    changeLang(){
      let lang = this.$route.params.lang === 'tw' ? 'en' : 'tw'
      this.$store.commit('updateLang', lang)
      this.$i18n.locale = lang
      this.$router.push({ name: this.$route.name, params: {lang: lang} });
      this.scrollTo(0);
    },
  },
  async mounted(){
    if (this.$route.params.lang !== this.$store.state.lang){
      this.$store.commit('updateLang', this.$route.params.lang)
      this.$i18n.locale = this.$route.params.lang
    }
    // this.$store.commit('updateWidth', document.body.clientWidth)
    this.$store.commit('updateIsDesktop', document.body.clientWidth >= 960)
    window.onresize = () => {
      // this.$store.commit('updateWidth', document.body.clientWidth)
      this.$store.commit('updateIsDesktop', document.body.clientWidth >= 960)
    }
    // connect wallet
    // 有 metamask
    if (typeof window.ethereum !== "undefined") {
      // get chain id
      let provider = new this.$eth.providers.Web3Provider(window.ethereum);
      let network = await provider.getNetwork();
      await this.checkChainId(network.chainId)

      if (!window.ethereum.selectedAddress){
        this.$store.commit('updateAccount', '')
        this.$cookies.remove('account')                                                                                      
      }

      // listener
      window.ethereum.on('chainChanged', this.checkChainId);
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0){
          this.$store.commit('updateAccount', '')
          this.$cookies.remove('account')
        }else{
          this.$store.commit('updateAccount', accounts[0]);
          this.$cookies.set('account', accounts[0])
        }
        window.location.reload()
      });
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/scss/global.scss';
@font-face{
  font-family: NotoSansCJKtc;
  src: url('./assets/fonts/NotoSansTC-Regular.otf')
}

@font-face {
  font-family: 'Hacked';
  src: url('./assets/fonts/HACKED.ttf')
}

#app{
  background: linear-gradient(#9d6f97 0%, #ceb0c1 20%, #bfb2a8 45%, #b3a2aa 67%, #a8d0da 89%, #ac7474 100%);
  font-family: NotoSansCJKtc;
  cursor: default;
}

.font-hacked{
  font-family: 'Hacked';
}

// common style
.can-click{
  cursor: pointer;
}
.bg-transparent{
  background-color: rgba(0, 0, 0, 0) !important;
}
.w-full{
  width: 100%;
}
.container {
  max-width: 1114px;
}
.text-space{
  line-height: 1.88;
  letter-spacing: 0.5px;
}
.cancel-btn-tobig {
  text-transform: none !important;
}
.primary-border{
  border: solid 1.5px var(--v-primary-base);
  border-radius: 105px;
  overflow: hidden;
  @include dai_vuetify_md{
    border-radius: 50px;
  }
}
.secondary-border{
  border: solid 1.5px var(--v-secondary-base);
  border-radius: 105px;
  @include dai_vuetify_md{
    border-radius: 50px;
  }
}
.white-border-bottom{
  border-bottom: solid 1px white;
}
.primary-border-bottom{
  border-bottom: solid 1px var(--v-primary-base);
}
.secondary-border-bottom{
  border-bottom: solid 1px var(--v-secondary-base);
}
.toasted-primary{
  font-family: 'DIN Condensed', sans-serif;
  background-color: var(--v-secondary-base) !important;
  color: #FFFFFF !important;
  box-shadow: 0 0 10px 0 var(--v-secondary-base) !important;
}
</style>