import Vue from "vue";
import VueI18n from "vue-i18n";
import store from '../store/index'
import langs from "@/i18n/index.js";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.locale,
  fallbackLocale: "en",
  messages: langs,
  //關閉黃色警告
  silentTranslationWarn: true
})
export default i18n