import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
const cookies = require('vue-cookies')

Vue.use(VueRouter)
Vue.use(cookies);

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // get user account from cookies
  try{
    let account = Vue.$cookies.get('account')
    if (account){
      store.commit('updateAccount', account)
    }
  }catch(error){
    console.log('error', error)
  }
  
  // update language
  if (!to.params.lang || !store.state.langs.includes(to.params.lang)){
    next('/en')
  }else{
    store.commit('updateLang', to.params.lang)
    next()
  }
});

export default router
