export default {
  about: 'In 2021 AD, the first year of the Metaverse, humans have established a parallel universe outside of the physical realm. \nPeople began to find spiritual sustenance in the Metaverse, and gradually digitized their lives...\nIn 2035 AD, due to over-exploitation of the Earth by humans, resources were running out, thus humans had no choice but to seek out another survival route. \nIn 2049 AD, upon humans\' discovery of an untouched land within the Metaverse called Pure Land, they hurriedly voyaged towards Pure Land and gradually disappeared on Earth. \nAfter the disappearance of humans, the belongings that were left behind have been inherited by the highly adaptable, highly reproductive and highly intellectual animal living in close proximity to humans - the "Pig".\nThe piggies inherited belongings that could not be brought by humans along with them, and developed a social structure, emerging as the new conqueror of the Earth.\nIn 2077 AD, the piggies stumbled upon the secret that humans created cryptocurrencies and the Metaverse. Seizing this opportunity, they have created a world that belongs to them - the "PiggyPunks"',
  totalText: 'Total PiggyPunks: ',
  piggies: 'piggies',
  preMintText: 'Pre-mint: No',
  roadmap: [
    'Project Funds\nKeep the funds to support the project operation.',
    'Make physical PiggyPunks products and organize lucky draws in the community to give back to early supporters.',
    'Expand advertising, community collaboration and welcome celebrity endorsement.',
    'Open NFT accessories market, it may contain parts that can upgrade or transform the piggies.',
    'Member-Exclusive PiggyPunks regional physical art exhibition.',
    'Open PiggyPunks gallery.\nLaunch 3D PiggyPunks',
    'Burn all the BabyPigToken we received.',
    'World tour physical member gathering, let\'s go to Times Square!\nStart to organize the BabyPig Art Foundation.',
  ],
  team: [
    'BabyPig Founder\nPerson in charge of PiggyPunks\nDeveloper\nMarketing',
    'Artist\nArt director'
  ],
  FAQ: [
    {
      title: '1. What is PiggyPunks?',
      content: 'PiggyPunks is a collection of BabyPig derivative NFTs limited to 6666 Piggy, each NFT represents a Piggy living in the PiggyPunks universe.\nEach Piggy is unique, and each accessory has its own rarity.\nBut no matter what Piggy you are, you are welcome to the world of PiggyPunks!',
    },
    {
      title: '2. Pricing strategy and profit flow?',
      content: 'In terms of pricing strategy, we hope that the initial price of PiggyPunks can be easily affordable, just like CryptoPunks in 2017.\nOr you can choose to pay for BabyPigToken to help us create deflation together.\nPiggyPunks\' funds flow is transparent. The overall total revenue will be divided into seven-thirds with the creator after deducting engineering and marketing costs. We promise to donate 15% of the remaining revenue to the International Animal Protection Organization(After all the PiggyPunks have been minted). After deducting the above various expenses, it will be gradually realized in accordance with the plan given in the roadmap. Stay tuned.',
    },
    {
      title: '3. How do I make sure that the minting mechanism is fair?',
      content: 'All the piggies will be minted only after the collector presses the "Mint" button. No Piggies is pre-minted, which avoids the possibility of cheating and makes sure the mechanism is fair.',
    },
    {
      title: '4. Is PiggyPunks limited?',
      content: 'Yes, definitely.\nWe will open the minting page of 6,666 pigs until all minting is completed. After all the piggies are born, we will close the minting function and keep the web page for gallery display.',
    },
    {
      title: '5. Where can holders trade their Piggies?',
      content: 'Collectors can trade their PiggyPunks on other secondary markets that we cooperate with.',
    },
    {
      title: '6. What will we do with the BabyPigTokens received?',
      content: 'Burn, Burn and Burn🔥',
    },
  ],
  comingSoon: 'Coming Soon',
  copySuccess: 'Copy Successfully',
  copyFail: 'Copy Failed',
}