let ethers = require("ethers");
let abi = require("./token_abi.js");
import store from "../store"

let detectEthereumProvider = require('@metamask/detect-provider');
let provider;
let contract;

export default {
    // 初始化
    init: async (status) => {
        if (status) {
            provider = await new ethers.providers.Web3Provider(await detectEthereumProvider());
            contract = await new ethers.Contract(store.state.piggyAddress, abi, provider.getSigner());
        } else {
            provider = await new ethers.providers.JsonRpcProvider(store.state.rpcUrl);
            contract = await new ethers.Contract(store.state.piggyAddress, abi, provider)
}
    },
    getBalance: async (address) => {
        let balance = await contract.balanceOf(address)
        return parseInt(ethers.utils.formatUnits(balance, "gwei"))
    },
    getAllowance: async (address) => {
        let allowance = await contract.allowance(address, store.state.contractAddress)
        return parseInt(ethers.utils.formatUnits(allowance, "gwei"))
    },
    approve: async () => {
        let amount = ethers.utils.parseEther('150000000000')
        const tx = await contract.approve(store.state.contractAddress, amount)
        return tx
    },
}