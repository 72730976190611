<template>
  <div class="index">
    <img class="mb-10" src="@/assets/img/piggy-punks-poster.webp" width="100%" alt="banner">
    <v-container class="d-flex flex-column align-center">
      <div id="mint-block">
        <div class="secondary--text text-center font-hacked font-weight-black rem-12 rem-md-20 mb-10">12 BUSD or 8 millions Baby Pig Token / pic</div>

        <div class="rem-22 rem-sm-48 font-dincond font-weight-black mb-10 d-flex justify-center">
          <span class="secondary--text mr-sm-2 mr-1">{{ countdown.day }}</span>
          <span class="mr-sm-2 mr-1">d</span>
          <span class="secondary--text mr-sm-2 mr-1">{{ countdown.hour }}</span>
          <span class="mr-sm-2 mr-1">h</span>
          <span class="secondary--text mr-sm-2 mr-1">{{ countdown.min }}</span>
          <span class="mr-sm-2 mr-1">m</span>
          <span class="secondary--text mr-sm-2 mr-1">{{ countdown.sec }}</span>
          <span class="mr-sm-2 mr-1">s</span>
        </div>

        <v-row :no-gutters="!$store.state.isDesktop" class="mint-content secondary-border" align="center">
          <v-col cols="12" md="6" class="text-space text-center mb-10 mb-md-0">
            <div class="d-flex justify-center align-center mb-1 mb-md-6">Total: <span class="rem-20 rem-md-28 font-weight-black secondary--text ml-1 mr-2">{{ remain.toLocaleString() }}</span> pics of PiggyPunks</div>
            <div class="text-center">Purchase limit: <span class="font-weight-black secondary--text rem-10 rem-md-16">{{ maxAmount }} / 10</span> pics</div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex justify-space-between mb-3">
              <v-btn dark rounded depressed :color="token === 'BUSD'? 'secondary':'#999999'" width="45%" @click="token = 'BUSD'">BUSD</v-btn>
              <v-btn dark rounded depressed :color="token === 'Piggy'? 'secondary':'#999999'" width="45%" @click="token = 'Piggy'">Piggy Token</v-btn>
            </div>
            <div class="primary-border d-flex justify-space-between mb-3">
              <div class="amount-icon primary can-click d-flex align-center" @click="changeAmount(-1)">
                <v-icon color="white" x-large>mdi-minus</v-icon>
              </div>
              <div class="d-flex justify-center align-center rem-22 font-weight-black">{{ amount }}</div>
              <div class="amount-icon primary can-click d-flex align-center" @click="changeAmount(1)">
                <v-icon color="white" x-large>mdi-plus</v-icon>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <v-btn dark rounded depressed color="primary" width="45%" @click="amount=maxAmount">MAX</v-btn>
              <v-btn v-if="allowance === 0 || allowance < balance" dark rounded depressed color="primary" width="45%" :loading="btnLoading" @click="approve()">approve</v-btn>
              <v-btn v-else dark rounded depressed color="primary" width="45%" :disabled="btnDisabled" @click="mint()">
                <span class="mr-2">MINT</span>
                <img src="@/assets/img/icon-connect.svg" width="30px" alt="connet wallet">
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <div id="about" class="d-flex flex-column flex-md-row align-center">
        <img class="mr-0 mr-md-8 mb-md-0 mb-10" src="@/assets/img/img-babypig.webp" width="100%" alt="baby-pig">
        <div>
          <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-4 mb-md-8 text-md-left text-center">
            About _ PiggyPunks
          </div>
          <div class="text-justify text-space" style="white-space: pre-wrap;" v-text="$t('about')"></div>
        </div>
      </div>

      <img class="mb-15" :src="`${require(`@/assets/img/pig-all${$store.state.isDesktop?'':'-m'}.webp`)}`" width="100%" alt="all piggy">

      <div id="piggynomics" class="d-flex flex-column align-center">
        <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-8 text-center">Piggynomics</div>
        <v-row class="secondary-border w-full pa-8" justify="center" align="center">
          <v-col cols="12" md="5" class="pl-0 pl-md-10">
            <div class="d-flex align-center mb-3 mb-md-5 rem-sm-4 rem-2">{{ $t('totalText') }}<span class="rem-20 rem-md-28 font-weight-black secondary--text ml-1 mr-2">6,666</span>{{ $t('piggies') }}</div>
            <div class="mb-7 rem-sm-4 rem-2">{{ $t('preMintText') }}</div>
            <v-text-field only-read filled solo flat dense color="white" append-icon="mdi-duplicate" v-model="$store.state.contractAddress">
              <template v-slot:append>
                <img class="can-click" src="@/assets/img/icon-copy.svg" width="30px" @click="copy()">
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="7">
            <img src="@/assets/img/piggypunks-animation.gif" alt="piggypunks-animation" width="100%">
          </v-col>
        </v-row>
      </div>

      <div id="roadmap">
        <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-8 text-center">RoadMap</div>
        <div
          class="roadmap-item d-flex flex-md-row flex-column align-center mb-15"
          v-for="(item, i) in roadmap"
          :key="i"
          :style="{left: `${item.left}%`, transform: `translate(-10%)`}"
        >
          <div class="roadmap-percent-wrap mr-0 mr-md-7">
            <img class="mb-md-0 mb-8" src="@/assets/img/img-roadmap.webp" width="163px" alt="roadmap">
            <div class="roadmap-percent-text rem-36 d-flex align-center font-weight-black white--text">{{ item.percent }}<span class="rem-12 ml-2">%</span></div>
          </div>
          <div class="roadmap-content">
            <div class="rem-6 text-center text-md-left">{{ $t('roadmap')[i] }}</div>
            <!--<div class="rem-6 text-center text-md-left">{{ item.content }}</div>-->
          </div>
        </div>
      </div>

      <div id="team" class="mb-15">
        <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-8 text-center">Team</div>
        <div class="d-flex flex-column flex-md-row justify-center">
          <div
            v-for="(item, i) in team"
            :key="i"
            class="d-flex flex-column align-center"
            :class="{'mr-md-7 mb-10 mb-md-0':i!==team.length-1}"
          >
            <img class="rounded-circle mb-md-10 mb-5" :src="`${require(`@/assets/img/${item.img}`)}`" width="250px">
            <div class="font-weight-black rem-28 text-center mb-4">
              {{ item.name }}
            </div>
            <div class="rem-6 text-center" style="white-space: pre-wrap;" v-text="$t('team')[i]"></div>
          </div>
        </div>
      </div>

      <div id="faq">
        <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-8 text-center">FAQs</div>
        <v-expansion-panels flat tile v-model="qaShow">
          <v-expansion-panel
            class="bg-transparent secondary-border-bottom"
            v-for="(item,i) in $t('FAQ')"
            :key="i"
          >
            <v-expansion-panel-header class="rem-8 rem-sm-12 font-weight-black">
              <div>{{ item.title }}</div>
              <template v-slot:actions>
                <v-icon :color="qaShow===i?'secondary':'black'">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="secondary--text rem-6 pa-5 pt-0" style="white-space: pre-wrap;" v-text="item.content">
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div id="community">
        <div class="secondary--text rem-md-28 rem-20 font-hacked font-weight-black mb-8 text-center">Community Links</div>
        <div class="d-flex justify-center align-center">
          <a
            rel="noreferrer noopener"
            target="_blank"
            class="mr-5"
            v-for="item in $store.state.community"
            :key="item.text"
            :href="item.link"
          >
            <img
              :alt="item.text"
              :src="`${require(`@/assets/img/icon-${item.text}-white.svg`)}`"
              width="24"
            />
          </a>
        </div>
      </div>
    </v-container>
    <div class="rem-0 white--text py-4 text-center" id="copyright">COPYRIGHT © 2021. PiggyPunks. ALL RIGHTS RESERVED.</div>
  </div>
</template>

<script>
  import base from '@/mixin/base'
  export default {
    name: 'Home',
    mixins: [base],
    data: () => ({
      roadmap: [
        {
          percent: 10,
          content: 'Project Funds\nKeep the funds to support the project operation.',
          left: 50,
        },
        {
          percent: 20,
          content: 'Make physical PiggyPunks products and organize lucky draws in the community to give back to early supporters.',
          left: 25,
        },
        {
          percent: 40,
          content: 'Expand advertising, community collaboration and welcome celebrity endorsement.',
          left: 50,
        },
        {
          percent: 60,
          content: 'Open NFT accessories market, it may contain parts that can upgrade or transform the piggies.',
          left: 25,
        },
        {
          percent: 70,
          content: 'Member-Exclusive PiggyPunks regional physical art exhibition.',
          left: 50,
        },
        {
          percent: 80,
          content: 'Open PiggyPunks gallery.',
          left: 25,
        },
        {
          percent: 99.9,
          content: 'Burn all the BabyPigToken we received.',
          left: 50,
        },
        {
          percent: 100,
          content: 'World tour physical member gathering, let\'s go to Times Square!',
          left: 25,
        },
      ],
      team: [
        {
          name: 'WilsonChen',
          img: 'avatar-wilson-chen.webp',
          title: 'BabyPig Founder\nPerson in charge of PiggyPunks\nProgrammer\nMarketing',
        },
        {
          name: 'Helix',
          img: 'avatar-helix.webp',
          title: 'Artist\nArt director',
        },
      ],
      qaShow: null,
      amount: 1,
      maxAmount: 0,
      remain: 'Loading',
      token: 'BUSD',
      balance: 0,
      allowance: 0,
      btnDisabled: true,
      btnLoading: false,
      timer: null,
      otherTime: null,
      countdown: {
        day: '--',
        hour: '--',
        min: '--',
        sec: '--',
      },
    }),
    watch: {
      token: {
        handler: async function(newVal){
          this.btnDisabled = true
          if (window.ethereum && window.ethereum.selectedAddress){
            if (newVal === 'BUSD'){
              this.allowance = await this.$busd.getAllowance(window.ethereum.selectedAddress)
              this.balance = await this.$busd.getBalance(window.ethereum.selectedAddress)
            }else{
              this.allowance = await this.$piggy.getAllowance(window.ethereum.selectedAddress)
              this.balance = await this.$piggy.getBalance(window.ethereum.selectedAddress)
            }
            this.btnDisabled = false
          }
        }
      }
    },
    computed: {
      price(){
        if (this.token === 'BUSD'){
          return this.amount * 12
        }else{
          return this.amount * 8000000
        }
      }
    },
    methods: {
      changeAmount(change){
        this.amount = this.amount + change > this.maxAmount ? this.maxAmount : 
                      this.amount + change < 1              ? 1              : this.amount + change
      },
      async approve(){
        if (typeof window.ethereum !== undefined){
          if (window.ethereum.selectedAddress){
            if (this.$store.state.isBsc){
              // piggy
              let result
              if (this.token === 'Piggy'){
                result = await this.$piggy.approve()
              }else{
                result = await this.$busd.approve()
              }
              console.log('result', result)
              this.btnLoading = true
              this.$toasted.show(result.hash)
              this.timer = window.setInterval(async () => {
                if (this.token === 'Piggy'){
                  this.allowance = await this.$piggy.getAllowance(window.ethereum.selectedAddress)
                }else{
                  this.allowance = await this.$busd.getAllowance(window.ethereum.selectedAddress)
                }
                if (this.allowance >= this.balance) {
                  window.clearInterval(this.timer)
                  this.btnLoading = false
                }
              }, 1000)
            }else{
              this.$toasted.show('Please Switch to Binance Smart Chain');
            }
          }else{
            await this.connectWallet()
          }
        }else{
          this.$toasted.show('Please install MetaMask');
        }
      },
      async mint(){
        if (typeof window.ethereum !== undefined){
          if (window.ethereum.selectedAddress){
            if (this.$store.state.isBsc){
              if (this.otherTime * 1000 <= Date.now()){
                if (this.balance >= this.price){
                  let result
                  try {
                    result = await this.$contract.mint(this.token, this.amount)
                    console.log('result', result)
                    if (result.hash){
                      this.$toasted.show(result.hash);
                    }
                    await this.getRemain()
                    await this.getMaxAmount(window.ethereum.selectedAddress)
                  }catch(error){
                    if (error.code === 4001){
                      this.$toasted.show('User Denied Transaction Signature');
                    }else{
                      console.log('error', error)
                    }
                  }
                }else{
                  this.$toasted.show('Under Balance');
                }
              }else{
                this.$toasted.show('Not Yet Open');
              }
            }else{
              this.$toasted.show('Please Switch to Binance Smart Chain');
            }
          }else{
            await this.connectWallet()
          }
        }else{
          this.$toasted.show('Please install MetaMask');
        }
      },
      async getRemain(){
        try{
          this.remain = await this.$contract.getRemainAmount()
        }catch(error){
          console.log('error', error)
        }
      },
      async getMaxAmount(address){
        this.maxAmount = await this.$contract.getMaxAmount(address)
      },
      async getTime(){
        try{
          this.otherTime = await this.$contract.getOtherTime()
          let _this = this;
          let sec, min, hour, day;
          this.timer = window.setInterval(function () {
            let nowTIme = Date.now();
            let endTime = parseInt(_this.otherTime) * 1000;
            let offsetTime = (endTime - nowTIme) / 1000;

            if (offsetTime <= 0) {
              _this.btnDisabled = false
              sec = min = hour = day = 0;
              window.clearInterval(_this.timer);
            } else {
              _this.btnDisabled = true
              sec = parseInt(offsetTime % 60);
              min = parseInt((offsetTime / 60) % 60);
              hour = parseInt((offsetTime / 60 / 60) % 24);
              day = parseInt(offsetTime / 60 / 60 / 24);
            }
            _this.countdown = {
              day: day < 10 ? `0${day}` : `${day}`,
              hour: hour < 10 ? `0${hour}` : `${hour}`,
              min: min < 10 ? `0${min}` : `${min}`,
              sec: sec < 10 ? `0${sec}` : `${sec}`
            };
          }, 1000);
        }catch(error){
          console.log('error', error)
        }
      },
      copy(){
        try{
          this.$copyText(this.$store.state.contractAddress)
          this.$toasted.show(this.$t('copySuccess'))
        }catch(error){
          this.$toasted.error(this.$t('copyFail'))
        }
      }
    },
    async mounted(){
      if (window.ethereum){
        let address = window.ethereum.selectedAddress || this.$cookies.get('account')
        console.log('address', address)
        if (address){
          await this.$contract.init(true)
          await this.$piggy.init(true)
          await this.$busd.init(true)
          try{
            await this.getMaxAmount(address)
            this.allowance = await this.$busd.getAllowance(address)
            console.log('this.allowance', this.allowance)
            this.balance = await this.$busd.getBalance(address)
            console.log('balance', this.balance)
          }catch(error){
            console.log('error', error)
          }
        }else{
          await this.$contract.init(false)
          this.maxAmount = 10
        }
      }else{
        await this.$contract.init(false)
        this.maxAmount = 10
      }

      await this.getTime()
      await this.getRemain()
    }
  }
</script>
<style lang="scss">
.index{
  .mint-content{
    padding: 5% 10%;
    @include dai_vuetify_md{
      padding: 40px 8px;
    }
    .amount-icon{
      padding: 4% 8%;
    }
  }
  #about{
    img[alt="baby-pig"]{
      max-width: 360px;
    }
  }
  #roadmap{
    position: relative;
    .roadmap-item{
      position: relative;
      @include dai_vuetify_md{
        position: static !important;
        transform: none !important;
      }
      .roadmap-percent-wrap{
        position: relative;
        width: fit-content;
        .roadmap-percent-text{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -60%);
          @include dai_vuetify_md{
            transform: translate(-50%, -75%);
          }
        }
      }
      .roadmap-content{
        width: 100%;
        white-space: pre-wrap;
        max-width: 450px;
        @media (min-width:960px) and (max-width:1100px) {
          max-width: 370px;
        }
      }
    }
  }
  #faq, #community{
    width: 60%;
    margin-bottom: 100px;
    max-width: 650px;
    @include dai_vuetify_md{
      width: 95%;
    }
  }
  #mint-block, #about, #piggynomics, #roadmap{
    width: 95%;
    max-width: 930px;
    margin-bottom: 80px;
  }
  #about{
    @include dai_vuetify_md{
      margin-bottom: 40px !important;
    }
  }
  #copyright{
    background-color: #fb4d63;
    width: 100%;
  }
}
</style>