import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import Toasted from 'vue-toasted';
import { ethers } from "ethers"
import contract from './contract/index'
import piggy from './contract/piggy'
import busd from './contract/busd'
import clipboard2 from './plugins/vue-clipboard2'
import '@/assets/scss/mixin.scss';

Vue.config.productionTip = false
Vue.prototype.$eth = ethers;
Vue.prototype.$contract = contract;
Vue.prototype.$piggy = piggy;
Vue.prototype.$busd = busd;

Vue.use(Toasted, {
  duration: 2000,
  keepOnHover: true,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  clipboard2,
  render: h => h(App)
}).$mount('#app')
