export default {
  about: '西元2021年，元宇宙元年，人類於現實世界之外建立了平行宇宙。\n人們開始將他們的精神寄託於元宇宙內，並將一切生活逐漸數位化⋯\n西元2035年，地球因為人類的過度開發，資源枯竭，人類不得不尋求更進一步生存的空間。\n西元2049年，人類在元宇宙中發現了一塊淨土Pure Land，紛紛遷徙至Pure Land而漸漸地消失在地球上。\n人類離開後，所有遺留下來的東西，都由適應力強、繁殖力快、智商高、又與人類生活圈貼近的動物-「豬」所繼承。\n豬仔們承接了人類所有帶不走的物品，發展出一套社會結構，成為地球新一代的統治者。\n西元2077年，豬仔們發現了人類創建加密貨幣與元宇宙的秘密，藉此建立了屬於他們的世界-「加密龐克豬」',
  totalText: '總量：',
  piggies: '隻小豬',
  preMintText: '提前鑄造：沒有',
  roadmap: [
    '項目預留資金\n為了後續營銷、路線圖、計畫等所預留之資金',
    '製作龐克豬實體商品，並舉辦抽獎等活動以回饋前期支持者',
    '擴大營銷、社群合作以及名人代言等',
    '開啟龐克豬NFT飾品市場（它可能可以升級您的龐克豬）',
    '會員專屬- 地區性龐克豬持有人藝術特展',
    '龐克豬線上畫廊開啟\n3D龐克豬推出',
    '燒毀所有已收到的寶貝豬代幣',
    '世界巡迴特展，讓龐克豬上時代廣場吧！\n開始建構寶貝豬藝文基金會',
  ],
  team: [
    '寶貝豬 創辦人\n龐克豬 項目負責人\n開發人員\n營銷負責人',
    '創作者\n藝術總監'
  ],
  FAQ: [
    {
      title: '1. 什麼是龐克豬？',
      content: '龐克豬是寶貝豬衍生之NFT合集，其限定總量為6666隻小豬。\n每個 NFT 代表一隻生活在 龐克豬 宇宙中的 小豬，而每隻小豬都是獨一無二的，每個配件也有自己的稀缺性。不管你是怎麼樣的小豬，我們都歡迎你一同加入龐克豬宇宙！',
    },
    {
      title: '2. 定價策略與資金流向？',
      content: '就定價策略而言，我們希望PiggyPunks的初始價格可以讓收藏家很輕易地負擔，就像CryptoPunks在2017年一樣。\n或是您也可以選擇支付寶貝豬代幣，一同幫助我們製造通縮。\n龐克豬的資金流向透明，整體總收益會再扣除工程、營銷費用後與創作者進行七三分潤，剩餘收益我們承若將捐出其中15%給國際動物保護組織。扣除以上種種支出後，會依照路線圖給的計畫逐步實現。敬請期待。',
    },
    {
      title: '3. 如何確定鑄造機制是公平的？',
      content: '所有的龐克豬只有在收藏家按下"鑄造"按鈕後才會進行生成。這代表沒有任何一隻小豬會被提前鑄造，這能避免作弊的行為發生並且證明鑄造機制的公平性。',
    },
    {
      title: '4. 龐克豬是限量的嗎？',
      content: '當然是。\n我們會開放6666隻小豬的鑄造頁面直到全數鑄造完畢。待所有的小豬出生後，我們會關閉鑄造功能並保留網頁做畫廊展示。',
    },
    {
      title: '5. 持有者可以在哪裡交易他們的龐克豬呢？',
      content: '收藏家可以在與我們合作的二級市場中進行交易。',
    },
    {
      title: '6. 我們會怎麼處理收到的寶貝豬代幣？',
      content: '燒毀、燒毀，還是燒毀。',
    },
  ],
  comingSoon: '請敬請期待',
  copySuccess: '已成功複製至剪貼簿',
  copyFail: '複製失敗',
}