import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    community: [
      {
        text: 'website',
        link: 'https://www.babypig.org/?pgid=kr8x2pan-b7cf17b6-8538-4ca7-a793-ccd4378e2cdb'
      },
      {
        text: 'twitter',
        link: 'https://twitter.com/BabyPigToken'
      },
      {
        text: 'discord',
        link: 'https://discord.gg/fw5EDz6jT6'
      },
      {
        text: 'telegram',
        link: 'https://t.me/babypigNFT'
      },
      {
        text: 'youtube',
        link: 'https://www.youtube.com/channel/UCOJm3LplxdXVL8trEMw69Ug'
      }
    ],
    locale: 'en',
    langs: ['tw', 'en'],
    account: '',
    chainId: ['0x38', 56],
    isBsc: false,
    isDesktop: false,
    contractAddress: "0x836bcfD1c219E8F420aD1b7dFA8d7b46bF7986A9",
    piggyAddress: "0x7b0b4C304B9149B692c1Acbc9dB4F4a1c17B2B91",
    busdAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    rpcUrl: 'https://bsc-dataseed.binance.org/',
  },
  mutations: {
    updateLang(state, lang){
      state.locale = lang
    },
    updateAccount(state, account){
      state.account = account
    },
    updateIsBsc(state, isBsc){
      state.isBsc = isBsc
    },
    updateIsDesktop(state, isDesktop){
      state.isDesktop = isDesktop
    },
  },
  actions: {
  },
  modules: {
  }
})
